var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{class:[_vm.tbodyClass, _vm.$style.tableBody]},_vm._l((Object.entries(_vm.tracks)),function(ref){
var trackTitle = ref[0];
var ref_1 = ref[1];
var sources = ref_1.sources;
var track = ref_1.track;
return _c('tr',{key:trackTitle,class:_vm.$style.tableRow},[_c('td',{class:[_vm.$style.tableCell, _vm.$style.tableFirstCol]},[_c('div',{class:_vm.$style.titleContainer},[_c('span',{class:_vm.$style.trackTitle},[_vm._v(_vm._s(track.title))]),_c('span',{class:_vm.$style.trackIswc},[_vm._v(_vm._s(track.iswc))])])]),_vm._l((Object.values(sources)),function(ref){
var source = ref.source;
var status = ref.status;
return _c('td',{key:source,class:_vm.$style.tableCell},[_c(status !== 'MISSING' ? 'button' : 'div',_vm._g({tag:"component",class:[
          _vm.$style.sourceStatusBtn,
          status !== 'MISSING' && _vm.$style.clickable ]},Object.assign({}, (status !== 'MISSING' && {
            click: function () { return _vm.onSourceStatusClick({ source: source, track: track }); },
          }))),[_c('SourceStatus',{attrs:{"status":status}})],1)],1)})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }