<template>
  <div :class="$style.container">
    <div :class="[$style.circle, $style[normalisedStatus], circleStyle]">
      <div
        :class="[
          $style.innerCircle,
          $style[normalisedStatus],
          innerCircleStyle,
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
import { TRACK_SOURCE_TYPE } from "../../constants/trackSourceType";

export default {
  name: "SourceStatus",
  props: {
    status: {
      type: String,
      required: true,
      default: TRACK_SOURCE_TYPE.MISSING,
    },
    circleStyle: {
      type: [String, Array, Object],
    },
    innerCircleStyle: {
      type: [String, Array, Object],
    },
  },
  computed: {
    normalisedStatus() {
      return this.status.replace(" ", "_").toLowerCase();
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.missing {
    border: 1px solid #bfc9d9;
  }

  &.found {
    border: 1px solid #37487a;
  }

  &.potential_duplicate {
    border: 1px solid #ee7e7e;
    // opacity: 40%;
  }
}

.innerCircle {
  border-radius: 50%;
  width: 12px;
  height: 12px;

  &.missing {
  }

  &.found {
    background-color: #37487a;
  }

  &.potential_duplicate {
    background-color: #ee7e7e;
  }
}
</style>