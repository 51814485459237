<template>
  <tbody :class="[tbodyClass, $style.tableBody]">
    <tr
      :class="$style.tableRow"
      v-for="[trackTitle, { sources, track }] of Object.entries(tracks)"
      :key="trackTitle"
    >
      <td :class="[$style.tableCell, $style.tableFirstCol]">
        <div :class="$style.titleContainer">
          <span :class="$style.trackTitle">{{ track.title }}</span>
          <span :class="$style.trackIswc">{{ track.iswc }}</span>
        </div>
      </td>
      <td
        :class="$style.tableCell"
        v-for="{ source, status } of Object.values(sources)"
        :key="source"
      >
        <component
          :is="status !== 'MISSING' ? 'button' : 'div'"
          :class="[
            $style.sourceStatusBtn,
            status !== 'MISSING' && $style.clickable,
          ]"
          v-on="{
            ...(status !== 'MISSING' && {
              click: () => onSourceStatusClick({ source, track }),
            }),
          }"
        >
          <SourceStatus :status="status" />
        </component>
      </td>
    </tr>
  </tbody>
</template>

<script>
import SourceStatus from "./SourceStatus";

export default {
  name: "SourcesTableBody",
  components: {
    SourceStatus,
  },
  props: {
    tracks: {
      type: Object,
    },
    tracksBySource: {
      type: Object,
    },
    tbodyClass: {
      type: [String, Object, Array],
    },
  },
  methods: {
    onSourceStatusClick({ source, track }) {
      const matchingTrack = this.tracksBySource[source][track.iswc];
      this.$emit("openCountryDetails", {
        track: matchingTrack,
        source,
      });
    },
  },
};
</script>

<style lang="scss" module>
.titleContainer {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
}
.trackTitle,
.trackIswc {
  display: block;
}

.trackTitle {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
}

.trackIswc {
  color: $color-grey-light;
}
.tableView {
  position: relative;
}
.tableContainer {
  width: calc(100vw - 10rem - 360px);
  overflow-x: scroll;
  margin-left: 360px;
}

.tracksTable {
  padding: 0;
}

.tableBody {
  .tableRow:nth-child(even) {
    .tableCell {
      background-color: hsla(217, 25%, 80%, 0.15);
    }
  }
}

.tableRow {
  display: flex;
  height: 60px;
}

.tableHeading {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
}

.tableCell {
  font-size: 14px;
  white-space: nowrap;
  padding: 0 2px;

  &:not(.tableFirstCol) {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tableFirstCol {
  width: 360px;
  font-weight: 500;
  position: absolute;
  top: auto;
  left: 0;
  display: flex;
  align-items: center;
  height: 60px;
}

.viewLink {
  margin-top: 3rem;
  display: block;
  color: $color-grey-light;

  &:hover {
    color: #333;
  }
}

.viewLinkIcon {
  margin-right: 0.5rem;
}

.sourceStatusBtn {
  border: none;
  background: transparent;
  border-radius: 50%;
  padding: 0 !important;

  &.clickable {
    cursor: pointer;
  }
}
</style>